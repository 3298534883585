<template>
  <ContentWrapper>
    <template v-slot:title>
       <div class="flex-between">
          <div>Game Dashboard</div>
          <div class="create-game-btn-desktop" v-if="userRole === 'admin' || userRole == 'trainer'" @click="navigateToCreateScreen">New Game</div>
          <div class="create-game-btn-mobile" v-if="userRole === 'admin'" @click="navigateToCreateScreen">
             <img src="../../../../assets/icons/cross.svg" alt="add">
          </div>
      </div>
    </template>
   
    <div class="game-lobby-wrapper">
      <div class="game-tabs">
        <router-link class="tabs" tag="a" :to="{name: this.userRole === 'admin' ? 'AdminPublicGameTab' : 'PublicGameTab'}">Public Game</router-link>
        <router-link class="tabs" tag="a" :to="{name: this.userRole === 'admin' ? 'AdminPrivateGameTab' : 'PrivateGameTab'}">Private Game</router-link>
      </div>
      <Container>
         <router-view></router-view>
      </Container>
    </div>
  </ContentWrapper>
</template>

<script>
export default {
  async created(){
    await this.checkLastAPICall();
  },
  methods:{
    navigateToCreateScreen(){
      if(this.userRole === 'admin'){
        this.$router.push({name:'AdminCreateGame'});
      }else{
        this.$router.push({name:'TrainerCreateGame'});
      }
    }
  }
}
</script>

<style lang="scss" scoped>

  .create-game-btn-desktop{
    padding: 10px 28px;
    font-size: 16px;
    color: var(--gray414241);
    background: white;
    border-radius: 999px;
    cursor: pointer;
    font-weight: 500;
    display: initial;
    @media screen and (max-width: 450px){
      display: none;
    }
  }

  .create-game-btn-mobile{
    display: none;
    background: white;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    place-items: center;
    cursor: pointer;
    @media screen and (max-width: 450px){
      display: grid;
      
    }

    img{
      height: 10px;
      width: auto;
      transform: rotate(45deg);
    }
  }

  .game-lobby-wrapper{
    max-width: 2000px;
    overflow: hidden;

    @media screen and (max-width:450px){
      height: calc(100vh - 60px);
    }
  }

  .game-tabs{
    display: flex;

    @media screen and (max-width: 450px){
      padding: 0 10px;
      background: var(--redC41230);
    }
  }

  .tabs{
    padding: 1rem 3rem;
    font-size: 18px;
    color: white;
    background: var(--gray414241);
    border-radius: 5px 0 0 0;
    font-weight: 700;
    letter-spacing: 0.5px;
    display: inline-block;
    text-align: center;

    @media screen and (max-width: 450px){
      flex-grow: 1;
      padding: .7rem 0.5rem;
      font-size: 16px;

    }
  }

  .tabs:nth-child(2){
    border-radius: 0 5px 0 0;
  }

  .router-link-exact-active{
    color: var(--gray414241);
    background: white;
  }

  .content-container{
    border-radius: 0 10px 10px 10px;
    margin-bottom: 0 !important;
    height: calc(100vh - 220px);
    overflow: auto;

    @media screen and (max-width: 768px){
      height: calc(100vh - 160px);
    }

    @media screen and (max-width: 600px){
      height: calc(100vh - 153px);
    }

    @media screen and (max-width: 450px){
      border-radius: 0;
      height: calc(100vh - 160px);
    }
  }
</style>